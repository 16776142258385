/*

  The structure of Tailwind powered classes:
  [tw style]-[group] 
  [tw style]-[group]/[variant]
  [tw style]-[group]-[state] 
  [tw style]-[group]-[state]/[variant]
  [tw style]-[group]-[property] 
  [tw style]-[group]-[property]/[variant]
  [tw style]-[group]-[state]-[property]
  [tw style]-[group]-[state]-[property]/[variant]

  Groups:
  - surface
  - content
  - decor
  - button
  - secondaryButton

  Groups (sections only):
  - primaryButton 
  - ui 
  - primaryUI 
  - secondaryUI 
  - border 
  - system 

  States:
  - hover
  - pressed
  - focus
  - disable
  - highlight

  Properties:
  - head 
  - text
  - border
  - bg

  Variants: 
  - s (refers to secondary)
  - 100
  - 200
  - 300
  - accent
  - contrast
  - success
  and more.

  Examples: 
  bg-surface/200, text-content-heading, text-decor/accent, bg-secondaryUI-hover

*/

/*
  Existing themes for sections: satellite, sky, sand
  Existing themes for tiles: satellite, sky, sand, soil
*/

/* THEME VARIABLES */
.section-satellite {
  /* SURFACE */
  --theme-surface-100: theme("colors.satellite.100");
  --theme-surface-200: theme("colors.satellite.200");
  --theme-surface-300: theme("colors.satellite.300");
  --theme-surface-contrast: theme("colors.others.white");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.sky.200");
  --theme-text-default-secondary: theme("colors.sky.200");
  --theme-text-heading: theme("colors.sky.200");
  --theme-text-interactive-text-link: theme("colors.sky.accent");

  /* BUTTON */
  --theme-text-interactive-button-primary: theme("colors.satellite.200");
  --theme-button-primary-default: theme("colors.sky.accent");
  --theme-button-primary-hover: theme("colors.shades.white.200");
  --theme-button-primary-focus: theme("colors.shades.white.400");
  --theme-button-primary-pressed: theme("colors.shades.white.400");
  --theme-button-secondary-default: theme("colors.sky.200");
  --theme-button-secondary-hover: theme("colors.sky.accent");
  --theme-button-secondary-focus: theme("colors.sky.accent");
  --theme-button-secondary-pressed: theme("colors.sky.accent");
  --theme-button-secondary-highlight: theme("colors.shades.white.100");
  --theme-button-disable-background: theme("colors.shades.white.200");
  --theme-button-disable-text: theme("colors.shades.white.400");

  /* UI */
  --theme-ui-primary-default: theme("colors.sky.accent");
  --theme-ui-primary-hover: theme("colors.shades.white.200");
  --theme-ui-primary-focus: theme("colors.shades.white.400");
  --theme-ui-primary-pressed: theme("colors.shades.white.400");
  --theme-ui-secondary-default: theme("colors.sky.200");
  --theme-ui-secondary-hover: theme("colors.sky.accent");
  --theme-ui-secondary-focus: theme("colors.sky.accent");
  --theme-ui-secondary-pressed: theme("colors.sky.accent");
  --theme-ui-secondary-highlight: theme("colors.shades.white.200");
  --theme-ui-secondary-contrast: theme("colors.others.black");
  --theme-ui-disable-background: theme("colors.shades.white.200");
  --theme-ui-disable-text: theme("colors.shades.white.400");

  /* UI - BORDER */
  --theme-border-100: theme("colors.shades.white.100");
  --theme-border-200: theme("colors.shades.white.200");
  --theme-border-300: theme("colors.shades.white.300");
  --theme-border-400: theme("colors.shades.white.400");
  --theme-border-500: theme("colors.others.white");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.satellite.100");
  --theme-decorative-pattern-200: theme("colors.satellite.200");
  --theme-decorative-pattern-300: theme("colors.satellite.300");
  --theme-decorative-pattern-400: theme("colors.satellite.100");
  --theme-decorative-pattern-accent: theme("colors.sky.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");

  /* SYSTEM */
  --theme-system-success: theme("colors.status.green.light");
  --theme-system-warning: theme("colors.status.amber.light");
  --theme-system-error: theme("colors.status.red.light");
}

.tile-satellite {
  /* SURFACE */
  --theme-surface-100: theme("colors.satellite.100");
  --theme-surface-200: theme("colors.satellite.200");
  --theme-surface-300: theme("colors.satellite.300");
  --theme-surface-accent: theme("colors.satellite.accent");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.sky.200");
  --theme-text-default-secondary: theme("colors.sky.200");
  --theme-text-heading: theme("colors.sky.200");
  --theme-text-interactive-text-link: theme("colors.sky.accent");

  /* BUTTON */
  --theme-button-secondary-default: theme("colors.sky.200");
  --theme-button-secondary-hover: theme("colors.sky.accent");
  --theme-button-secondary-focus: theme("colors.sky.accent");
  --theme-button-secondary-pressed: theme("colors.sky.accent");
  --theme-button-secondary-highlight: theme("colors.shades.white.100");
  --theme-button-disable-background: theme("colors.shades.white.200");
  --theme-button-disable-text: theme("colors.shades.white.400");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.satellite.100");
  --theme-decorative-pattern-200: theme("colors.satellite.200");
  --theme-decorative-pattern-300: theme("colors.satellite.300");
  --theme-decorative-pattern-400: theme("colors.sky.300");
  --theme-decorative-pattern-accent: theme("colors.sky.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");
}

.section-sky {
  /* SURFACE */
  --theme-surface-100: theme("colors.sky.100");
  --theme-surface-200: theme("colors.sky.200");
  --theme-surface-300: theme("colors.sky.300");
  --theme-surface-contrast: theme("colors.others.white");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.satellite.200");
  --theme-text-default-secondary: theme("colors.satellite.200");
  --theme-text-heading: theme("colors.satellite.200");
  --theme-text-interactive-text-link: theme("colors.satellite.accent");

  /* BUTTON */
  --theme-text-interactive-button-primary: theme("colors.satellite.200");
  --theme-button-primary-default: theme("colors.sky.accent");
  --theme-button-primary-hover: theme("colors.shades.white.200");
  --theme-button-primary-focus: theme("colors.shades.white.200");
  --theme-button-primary-pressed: theme("colors.shades.white.400");
  --theme-button-secondary-default: theme("colors.satellite.200");
  --theme-button-secondary-hover: theme("colors.satellite.accent");
  --theme-button-secondary-focus: theme("colors.satellite.accent");
  --theme-button-secondary-pressed: theme("colors.satellite.accent");
  --theme-button-secondary-highlight: theme("colors.shades.black.100");
  --theme-button-disable-background: theme("colors.shades.black.200");
  --theme-button-disable-text: theme("colors.shades.black.400");

  /* UI */
  --theme-ui-primary-default: theme("colors.sky.accent");
  --theme-ui-primary-hover: theme("colors.shades.white.200");
  --theme-ui-primary-focus: theme("colors.shades.white.400");
  --theme-ui-primary-pressed: theme("colors.shades.white.400");
  --theme-ui-secondary-default: theme("colors.satellite.200");
  --theme-ui-secondary-hover: theme("colors.satellite.accent");
  --theme-ui-secondary-focus: theme("colors.satellite.accent");
  --theme-ui-secondary-pressed: theme("colors.satellite.accent");
  --theme-ui-secondary-highlight: theme("colors.shades.black.100");
  --theme-ui-secondary-contrast: theme("colors.others.white");
  --theme-ui-disable-background: theme("colors.shades.black.200");
  --theme-ui-disable-text: theme("colors.shades.black.400");

  /* UI - BORDER */
  --theme-border-100: theme("colors.shades.black.100");
  --theme-border-200: theme("colors.shades.black.200");
  --theme-border-300: theme("colors.shades.black.300");
  --theme-border-400: theme("colors.shades.black.400");
  --theme-border-500: theme("colors.others.black");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.sky.100");
  --theme-decorative-pattern-200: theme("colors.sky.200");
  --theme-decorative-pattern-300: theme("colors.sky.300");
  --theme-decorative-pattern-400: theme("colors.satellite.100");
  --theme-decorative-pattern-accent: theme("colors.sky.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");

  /* SYSTEM */
  --theme-system-success: theme("colors.status.green.dark");
  --theme-system-warning: theme("colors.status.amber.dark");
  --theme-system-error: theme("colors.status.red.dark");
}

.tile-sky {
  /* SURFACE */
  --theme-surface-100: theme("colors.sky.100");
  --theme-surface-200: theme("colors.sky.200");
  --theme-surface-300: theme("colors.sky.300");
  --theme-surface-accent: theme("colors.sky.accent");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.satellite.200");
  --theme-text-default-secondary: theme("colors.satellite.200");
  --theme-text-heading: theme("colors.satellite.200");
  --theme-text-interactive-text-link: theme("colors.satellite.accent");

  /* BUTTON */
  --theme-button-secondary-default: theme("colors.satellite.200");
  --theme-button-secondary-hover: theme("colors.satellite.accent");
  --theme-button-secondary-focus: theme("colors.satellite.accent");
  --theme-button-secondary-pressed: theme("colors.satellite.accent");
  --theme-button-secondary-highlight: theme("colors.shades.black.100");
  --theme-button-disable-background: theme("colors.shades.black.200");
  --theme-button-disable-text: theme("colors.shades.black.400");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.sky.100");
  --theme-decorative-pattern-200: theme("colors.sky.200");
  --theme-decorative-pattern-300: theme("colors.sky.300");
  --theme-decorative-pattern-400: theme("colors.satellite.100");
  --theme-decorative-pattern-accent: theme("colors.sky.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");
}

.section-sand {
  /* SURFACE */
  --theme-surface-100: theme("colors.sand.100");
  --theme-surface-200: theme("colors.sand.200");
  --theme-surface-300: theme("colors.sand.300");
  --theme-surface-contrast: theme("colors.others.white");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.soil.300");
  --theme-text-default-secondary: theme("colors.soil.300");
  --theme-text-heading: theme("colors.soil.300");
  --theme-text-interactive-text-link: theme("colors.soil.accent");

  /* BUTTON */
  --theme-text-interactive-button-primary: theme("colors.soil.300");
  --theme-button-primary-default: theme("colors.sand.accent");
  --theme-button-primary-hover: theme("colors.shades.white.200");
  --theme-button-primary-focus: theme("colors.shades.white.200");
  --theme-button-primary-pressed: theme("colors.shades.white.400");
  --theme-button-secondary-default: theme("colors.soil.200");
  --theme-button-secondary-hover: theme("colors.soil.accent");
  --theme-button-secondary-focus: theme("colors.soil.accent");
  --theme-button-secondary-pressed: theme("colors.soil.accent");
  --theme-button-secondary-highlight: theme("colors.shades.black.100");
  --theme-button-disable-background: theme("colors.shades.black.200");
  --theme-button-disable-text: theme("colors.shades.black.400");

  /* UI */
  --theme-ui-primary-default: theme("colors.sand.accent");
  --theme-ui-primary-hover: theme("colors.shades.white.200");
  --theme-ui-primary-focus: theme("colors.shades.white.400");
  --theme-ui-primary-pressed: theme("colors.shades.white.400");
  --theme-ui-secondary-default: theme("colors.soil.300");
  --theme-ui-secondary-hover: theme("colors.soil.accent");
  --theme-ui-secondary-focus: theme("colors.soil.accent");
  --theme-ui-secondary-pressed: theme("colors.soil.accent");
  --theme-ui-secondary-highlight: theme("colors.shades.black.100");
  --theme-ui-secondary-contrast: theme("colors.others.white");
  --theme-ui-disable-background: theme("colors.shades.black.200");
  --theme-ui-disable-text: theme("colors.shades.black.400");

  /* UI - BORDER */
  --theme-border-100: theme("colors.shades.black.100");
  --theme-border-200: theme("colors.shades.black.200");
  --theme-border-300: theme("colors.shades.black.300");
  --theme-border-400: theme("colors.shades.black.400");
  --theme-border-500: theme("colors.others.black");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.sand.100");
  --theme-decorative-pattern-200: theme("colors.sand.200");
  --theme-decorative-pattern-300: theme("colors.sand.300");
  --theme-decorative-pattern-400: theme("colors.soil.100");
  --theme-decorative-pattern-accent: theme("colors.sand.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");

  /* SYSTEM */
  --theme-system-success: theme("colors.status.green.dark");
  --theme-system-warning: theme("colors.status.amber.dark");
  --theme-system-error: theme("colors.status.red.dark");
}

.tile-sand {
  /* SURFACE */
  --theme-surface-100: theme("colors.sand.100");
  --theme-surface-200: theme("colors.sand.200");
  --theme-surface-300: theme("colors.sand.300");
  --theme-surface-accent: theme("colors.sand.accent");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.soil.300");
  --theme-text-default-secondary: theme("colors.soil.300");
  --theme-text-heading: theme("colors.soil.300");
  --theme-text-interactive-text-link: theme("colors.soil.accent");

  /* BUTTON */
  --theme-button-secondary-default: theme("colors.soil.300");
  --theme-button-secondary-hover: theme("colors.soil.accent");
  --theme-button-secondary-focus: theme("colors.soil.accent");
  --theme-button-secondary-pressed: theme("colors.soil.accent");
  --theme-button-secondary-highlight: theme("colors.shades.black.100");
  --theme-button-disable-background: theme("colors.shades.black.200");
  --theme-button-disable-text: theme("colors.shades.black.400");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.sand.100");
  --theme-decorative-pattern-200: theme("colors.sand.200");
  --theme-decorative-pattern-300: theme("colors.sand.300");
  --theme-decorative-pattern-400: theme("colors.soil.100");
  --theme-decorative-pattern-accent: theme("colors.sand.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");
}

.tile-soil {
  /* SURFACE */
  --theme-surface-100: theme("colors.soil.100");
  --theme-surface-200: theme("colors.soil.200");
  --theme-surface-300: theme("colors.soil.300");
  --theme-surface-accent: theme("colors.soil.accent");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.sand.200");
  --theme-text-default-secondary: theme("colors.sand.200");
  --theme-text-heading: theme("colors.sand.200");
  --theme-text-interactive-text-link: theme("colors.sand.accent");

  /* BUTTON */
  --theme-button-secondary-default: theme("colors.sand.200");
  --theme-button-secondary-hover: theme("colors.sand.accent");
  --theme-button-secondary-focus: theme("colors.sand.accent");
  --theme-button-secondary-pressed: theme("colors.sand.accent");
  --theme-button-secondary-highlight: theme("colors.shades.black.100");
  --theme-button-disable-background: theme("colors.shades.black.200");
  --theme-button-disable-text: theme("colors.shades.black.400");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.soil.100");
  --theme-decorative-pattern-200: theme("colors.soil.200");
  --theme-decorative-pattern-300: theme("colors.soil.300");
  --theme-decorative-pattern-400: theme("colors.sand.300");
  --theme-decorative-pattern-accent: theme("colors.sand.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");
}

.section-white {
  /* SURFACE */
  --theme-surface-100: theme("colors.others.white");
  --theme-surface-200: theme("colors.shades.black.100");
  --theme-surface-300: theme("colors.shades.black.200");
  --theme-surface-contrast: theme("colors.others.black");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.others.black");
  --theme-text-default-secondary: theme("colors.shades.black.400");
  --theme-text-heading: theme("colors.others.black");
  --theme-text-interactive-text-link: theme("colors.satellite.accent");

  /* BUTTON */
  --theme-text-interactive-button-primary: theme("colors.others.white");
  --theme-button-primary-default: theme("colors.satellite.accent");
  --theme-button-primary-hover: theme("colors.satellite.100");
  --theme-button-primary-focus: theme("colors.satellite.100");
  --theme-button-primary-pressed: theme("colors.satellite.200");
  --theme-button-secondary-default: theme("colors.shades.black.300");
  --theme-button-secondary-hover: theme("colors.shades.black.400");
  --theme-button-secondary-focus: theme("colors.shades.black.400");
  --theme-button-secondary-pressed: theme("colors.shades.black.400");
  --theme-button-secondary-highlight: theme("colors.shades.black.100");
  --theme-button-disable-background: theme("colors.shades.black.200");
  --theme-button-disable-text: theme("colors.shades.black.400");

  /* UI */
  --theme-ui-primary-default: theme("colors.satellite.accent");
  --theme-ui-primary-hover: theme("colors.satellite.100");
  --theme-ui-primary-focus: theme("colors.satellite.100");
  --theme-ui-primary-pressed: theme("colors.satellite.200");
  --theme-ui-secondary-default: theme("colors.shades.black.300");
  --theme-ui-secondary-hover: theme("colors.shades.black.400");
  --theme-ui-secondary-focus: theme("colors.shades.black.400");
  --theme-ui-secondary-pressed: theme("colors.shades.black.400");
  --theme-ui-secondary-highlight: theme("colors.shades.black.100");
  --theme-ui-secondary-contrast: theme("colors.others.white");
  --theme-ui-disable-background: theme("colors.shades.black.200");
  --theme-ui-disable-text: theme("colors.shades.black.400");

  /* UI - BORDER */
  --theme-border-100: theme("colors.shades.black.100");
  --theme-border-200: theme("colors.shades.black.200");
  --theme-border-300: theme("colors.shades.black.300");
  --theme-border-400: theme("colors.shades.black.400");
  --theme-border-500: theme("colors.others.black");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.shades.black.100");
  --theme-decorative-pattern-200: theme("colors.shades.black.200");
  --theme-decorative-pattern-300: theme("colors.shades.black.300");
  --theme-decorative-pattern-400: theme("colors.shades.black.400");
  --theme-decorative-pattern-accent: theme("colors.satellite.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");

  /* SYSTEM */
  --theme-system-success: theme("colors.status.green.dark");
  --theme-system-warning: theme("colors.status.amber.dark");
  --theme-system-error: theme("colors.status.red.dark");
}

.tile-white {
  /* SURFACE */
  --theme-surface-100: theme("colors.others.white");
  --theme-surface-200: theme("colors.shades.black.100");
  --theme-surface-300: theme("colors.shades.black.200");
  --theme-surface-accent: theme("colors.satellite.accent");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.others.black");
  --theme-text-default-secondary: theme("colors.shades.black.400");
  --theme-text-heading: theme("colors.others.black");
  --theme-text-interactive-text-link: theme("colors.satellite.accent");

  /* BUTTON */
  --theme-button-secondary-default: theme("colors.shades.black.300");
  --theme-button-secondary-hover: theme("colors.shades.black.400");
  --theme-button-secondary-focus: theme("colors.shades.black.400");
  --theme-button-secondary-pressed: theme("colors.shades.black.400");
  --theme-button-secondary-highlight: theme("colors.shades.black.100");
  --theme-button-disable-background: theme("colors.shades.black.200");
  --theme-button-disable-text: theme("colors.shades.black.400");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.shades.black.100");
  --theme-decorative-pattern-200: theme("colors.shades.black.200");
  --theme-decorative-pattern-300: theme("colors.shades.black.300");
  --theme-decorative-pattern-400: theme("colors.shades.black.400");
  --theme-decorative-pattern-accent: theme("colors.satellite.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");
}

.section-soil {
  /* SURFACE */
  --theme-surface-100: theme("colors.soil.100");
  --theme-surface-200: theme("colors.soil.200");
  --theme-surface-300: theme("colors.soil.300");
  --theme-surface-contrast: theme("colors.others.white");

  /* CONTENT */
  --theme-text-default-primary: theme("colors.others.white");
  --theme-text-default-secondary: theme("colors.shades.white.400");
  --theme-text-heading: theme("colors.others.white");
  --theme-text-interactive-text-link: theme("colors.sand.accent");

  /* BUTTON */
  --theme-text-interactive-button-primary: theme("colors.others.white");
  --theme-button-primary-default: theme("colors.soil.accent");
  --theme-button-primary-hover: theme("colors.shades.white.200");
  --theme-button-primary-focus: theme("colors.shades.white.200");
  --theme-button-primary-pressed: theme("colors.shades.white.400");
  --theme-button-secondary-default: theme("colors.sand.100");
  --theme-button-secondary-hover: theme("colors.sand.accent");
  --theme-button-secondary-focus: theme("colors.sand.accent");
  --theme-button-secondary-pressed: theme("colors.sand.accent");
  --theme-button-secondary-highlight: theme("colors.shades.black.100");
  --theme-button-disable-background: theme("colors.shades.black.200");
  --theme-button-disable-text: theme("colors.shades.black.400");

  /* UI */
  --theme-ui-primary-default: theme("colors.soil.accent");
  --theme-ui-primary-hover: theme("colors.shades.white.200");
  --theme-ui-primary-focus: theme("colors.shades.white.400");
  --theme-ui-primary-pressed: theme("colors.shades.white.400");
  --theme-ui-secondary-default: theme("colors.sand.100");
  --theme-ui-secondary-hover: theme("colors.sand.accent");
  --theme-ui-secondary-focus: theme("colors.sand.accent");
  --theme-ui-secondary-pressed: theme("colors.sand.accent");
  --theme-ui-secondary-highlight: theme("colors.shades.black.100");
  --theme-ui-secondary-contrast: theme("colors.soil.300");
  --theme-ui-disable-background: theme("colors.shades.black.200");
  --theme-ui-disable-text: theme("colors.shades.black.400");

  /* UI - BORDER */
  --theme-border-100: theme("colors.shades.white.100");
  --theme-border-200: theme("colors.shades.white.200");
  --theme-border-300: theme("colors.shades.white.300");
  --theme-border-400: theme("colors.shades.white.400");
  --theme-border-500: theme("colors.others.white");

  /* DECOR */
  --theme-decorative-pattern-100: theme("colors.soil.100");
  --theme-decorative-pattern-200: theme("colors.soil.200");
  --theme-decorative-pattern-300: theme("colors.soil.300");
  --theme-decorative-pattern-400: theme("colors.soil.accent");
  --theme-decorative-pattern-accent: theme("colors.sand.accent");
  --theme-decorative-pattern-white: theme("colors.others.white");

  /* SYSTEM */
  --theme-system-success: theme("colors.status.green.light");
  --theme-system-warning: theme("colors.status.amber.light");
  --theme-system-error: theme("colors.status.red.light");
}
